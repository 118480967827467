import { BackLink, Button, ErrorSummary, Label } from "nhsuk-react-components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useWebform } from "../hooks";
import CompleteButton from "./CompleteButton";
import Step from "./Step";
import { Attributes, ContactFlow, Intents, Module } from "./types";
import { deleteAttributesRecursive, processScript } from "./utils";
import LoadingIcon from "../components/LoadingIcon/LoadingIcon";
import Summary from "./Summary";
import BrowserUnloadWarning from "../components/BrowserUnloadWarning/BrowserUnloadWarning";

type ScriptPanelProps = {
  scriptId: string;
  campaignId: string;
  customerId: string;
};

const Script = (props: ScriptPanelProps): JSX.Element => {
  const [attributes, setAttributes] = useState<Attributes | null>(null);
  const [contactFlow, setContactFlow] = useState<ContactFlow | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [step, setStep] = useState("1");
  const { webform } = useWebform();

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (typeof e.preventDefault === "function") {
      if (!formSubmitted) {
        e.preventDefault();
        e.returnValue = "";
        // Let the browser handle the event if it doesn't support beforeunload
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formSubmitted]);

  useEffect(() => {
    if (!props.scriptId || !props.campaignId || !props.customerId) {
      return;
    }

    (async () => {
      const [customerData] = await webform.getCustomer(
        props.campaignId,
        props.customerId
      );

      setAttributes({
        ...(customerData?.attributes ?? {}),
        ...(customerData?.updatedAttributes ?? {}),
      });

      const [contactFlow] = await webform.getScript(props.scriptId);
      setContactFlow(contactFlow);
    })();
  }, [props.scriptId, props.campaignId, props.customerId, webform]);

  const modules = useMemo(() => {
    if (contactFlow && attributes) {
      const result = processScript({
        attributes: attributes,
        modules: contactFlow?.website.during,
      });

      setAttributes(result.attributes);
      return result.flattenedModules;
    } else {
      return [];
    }
  }, [attributes, contactFlow]);

  const module = useMemo(() => {
    const module = modules.find((i: Module) => i.id === step);
    return module;
  }, [modules, step]);

  const handleAnswerChange = useCallback(
    (module: Module, value: string) => {
      if (!contactFlow || !("attribute" in module)) {
        return;
      }

      setAttributes((previousAttributes) => {
        let tmpAttributes = { ...previousAttributes };

        if (modules && Array.isArray(modules) && modules.length > 0) {
          const index = modules.findIndex((i) => i.id === step);
          const modulesToProcess = modules?.slice(index + 1, modules.length);
          if (modulesToProcess && modulesToProcess.length > 0) {
            tmpAttributes = deleteAttributesRecursive(
              tmpAttributes,
              modulesToProcess
            );
          }
        }

        return {
          ...tmpAttributes,
          [module?.attribute]: value,
        };
      });
    },
    [contactFlow, modules, step]
  );

  const handleNext = useCallback(() => {
    setStep((currentStep) => {
      for (let i = 0; i < modules.length; i++) {
        if (modules[i]?.id === currentStep) {
          const nextId = ++i;
          return modules[nextId]?.id ?? "1";
        }
      }

      return "1";
    });
  }, [modules]);

  const handleBack = useCallback(() => {
    setStep((currentStep) => {
      let previousStep: Module | null = null;

      for (let i = 0; i < modules.length; i++) {
        if (previousStep && modules[i]?.id === currentStep) {
          return previousStep ? previousStep?.id : "1";
        }

        previousStep = modules[i];
      }

      return "1";
    });
  }, [modules]);

  const isNextButtonEnabled = useCallback(
    (module: Module): boolean => {
      if (module.type === "Ask") {
        return (
          "attribute" in module &&
          module?.attribute?.length > 0 &&
          attributes !== null &&
          (module?.attribute || "") in attributes &&
          attributes[module.attribute] !== null &&
          attributes[module.attribute] !== undefined &&
          (module.intent !== Intents.OpenEnded ||
            (module.intent === Intents.OpenEnded &&
              module.validation?.maxLength !== undefined &&
              typeof module.validation.maxLength === "number" &&
              attributes[module.attribute].length <=
                module.validation.maxLength))
        );
      }

      return true;
    },
    [attributes]
  );

  if (!attributes || !contactFlow) {
    // Accessibility - Page should contain a level-one heading
    return <LoadingIcon />;
  }

  if (!module) {
    return (
      <ErrorSummary
        aria-labelledby="error-summary-title"
        role="alert"
        tabIndex={-1}
      >
        {/* Accessibility - Page should contain a level-one heading. TODO: Render error summary title as <h1 /> (default is <h2 />) */}
        <ErrorSummary.Title id="error-summary-title">
          There is a problem
        </ErrorSummary.Title>
        <ErrorSummary.Body>
          <p>Please try again later</p>
        </ErrorSummary.Body>
      </ErrorSummary>
    );
  }

  return (
    <>
      {step !== "1" && !formSubmitted && (
        <BackLink
          onClick={handleBack}
          style={{
            marginBottom: "24px",
            marginTop: "16px",
          }}
        >
          Back
        </BackLink>
      )}
      <BrowserUnloadWarning></BrowserUnloadWarning>
      {module.type !== "EndCall" && (
        <>
          <Step
            attributes={attributes}
            onChangeAnswer={handleAnswerChange}
            module={module}
          />

          <Button
            onClick={handleNext}
            disabled={!isNextButtonEnabled(module)}
            style={{ padding: "12px 24px" }}
          >
            Next
          </Button>

          {/* <HelpSection /> */}
        </>
      )}

      {module.type === "EndCall" && (
        <>
          {/* Valid code, works but not fully tested */}
          {module?.text && <Label isPageHeading>{module.text}</Label>}
          <h2 style={{ marginBottom: "20px" }}>Summary</h2>
          <p>Please click the Submit button below to send us your answers</p>
          <Summary modules={modules} attributes={attributes} />

          <CompleteButton
            attributes={attributes}
            modules={modules}
            onSuccess={() => {
              window.removeEventListener("beforeunload", handleBeforeUnload);
              setFormSubmitted(true);
            }}
            onError={() => {
              console.error("Failed ...");
            }}
          />
        </>
      )}
    </>
  );
};

export default Script;
