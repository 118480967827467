import { useField, useFormikContext } from "formik";
import { Checkboxes } from "nhsuk-react-components";
import { FormElementProps } from "nhsuk-react-components/dist/util/types/FormTypes";
import {
  ChangeEvent,
  Children,
  cloneElement,
  HTMLProps,
  isValidElement,
  useContext,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts";

// Copied from node_modules\nhsuk-react-components\dist\components\checkboxes\Checkboxes.d.ts
interface CheckboxesProps extends HTMLProps<HTMLDivElement>, FormElementProps {
  idPrefix?: string;
}

interface CheckboxesFormikProps extends CheckboxesProps {
  name: string;
}

interface FormValues {
  [key: string]: string;
}

export default function CheckboxesFormik({
  children,
  name,
  ...props
}: CheckboxesFormikProps) {
  const [{ value, ...field }, { error, touched }, { setValue }] =
    useField<string[]>(name);
  const { setFieldError } = useFormikContext<FormValues>();
  const { clearSignInError } = useContext(AuthContext);

  const childProps = useMemo(
    () => ({
      ...field,
      onChange: async (e: ChangeEvent<any>) => {
        if (touched) {
          setFieldError(name, "");
          clearSignInError();
        }
        setValue(
          value.includes(e.target.value)
            ? value.filter((value: string) => value !== e.target.value)
            : [...value, e.target.value]
        );
      },
    }),
    [field, setValue, value]
  );

  return (
    <Checkboxes error={error}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) return cloneElement(child, childProps);
      })}
    </Checkboxes>
  );
}
