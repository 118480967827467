import { CloseIcon, Button, Footer, Header } from "nhsuk-react-components";
import { Box, IconButton, Modal } from "@mui/material";
import styles from "./TermsAndConditionsModal.module.scss";
import TermsAndConditions from "../../../pages/TermsAndConditions";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflowY: "scroll",
};

type TermsOfServiceModalProps = {
  openModal: boolean;
  handleCloseModal: () => void;
};

export default function TermsOfServiceModal(props: TermsOfServiceModalProps) {
  const { openModal, handleCloseModal } = props;
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          display="flex"
          alignItems="center"
          style={{
            backgroundColor: styles.colorNhsukBlue,
          }}
        >
          <Box flexGrow={1}>
            <Header transactional>
              <Header.Container>
                <Header.Logo />
                <Header.ServiceName>
                  Terms and Conditions for NHS Patient Waiting List Services
                </Header.ServiceName>
              </Header.Container>
            </Header>
          </Box>
          <Box>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon style={{ fill: styles.colorNhsukWhite }} />
            </IconButton>
          </Box>
        </Box>
        <Box style={{ padding: "1em" }}>
          <TermsAndConditions width="full"></TermsAndConditions>
        </Box>
        <Footer>
          <Button onClick={handleCloseModal}>Close</Button>
        </Footer>
      </Box>
    </Modal>
  );
}
