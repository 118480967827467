import { useMediaQuery, useTheme } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { DateInput, DateInputProps, DateInputValue } from "..";
import { useContext } from "react";
import { AuthContext } from "../../contexts";
import { DateInputChangeEvent } from "../DateInput";

interface DateFormikProps extends DateInputProps {
  name: string;
}

export default function DateFormik({ width, name, ...props }: DateFormikProps) {
  const [, { error, touched }, { setValue }] = useField<DateInputValue>(name);
  const theme = useTheme();
  const mobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { setFieldError } = useFormikContext();
  const { clearSignInError } = useContext(AuthContext);

  const handleFieldChange = (event: DateInputChangeEvent) => {
    const { value } = event.target;
    if (touched) {
      setFieldError(name, "");
      clearSignInError();
    }
    setValue(value);
  };

  return (
    <DateInput
      {...props}
      onChange={handleFieldChange}
      error={error}
      width={mobileOrTablet ? undefined : width}
    />
  );
}
