import axios, { AxiosError } from "axios";
import { CompleteWebformRequest, HttpMethod, HttpMethods } from "./Types.crm";
import { ICustomer, IScript } from "./Types";
import { ContactFlow } from "../Script/types";

export default class WebformService {
  private customAuthHeaders: { [key: string]: string } = {};

  #url = () => {
    const result = process.env?.REACT_APP_WEBFORM_URL;
    if (!result) {
      throw new Error("Webform URL not configured");
    }

    if (!result.startsWith("https://")) {
      throw new Error("Webform CRM URL must use https");
    }

    return result;
  };

  setCustomAuthHeaders(headers: { [key: string]: string }) {
    this.customAuthHeaders = headers;
  }

  #defaultHeaders = async () => ({
    Accept: "application/json",
  });

  #getHeaders = async (
    method: HttpMethod,
    body?: string
  ): Promise<{ [key: string]: any }> => {
    const additionalHeaders: { [key: string]: any } = {};

    switch (method) {
      case HttpMethods.Put:
      case HttpMethods.Post:
      case HttpMethods.Delete: {
        if (body) {
          additionalHeaders["Content-Length"] = body.length.toString();
          additionalHeaders["Content-Type"] = "application/json";
        }
        break;
      }
      default:
        break;
    }

    return Object.assign(
      {},
      await this.#defaultHeaders(),
      this.customAuthHeaders,
      additionalHeaders
    );
  };

  async completeWebform(body: CompleteWebformRequest) {
    const url = `${this.#url()}/wf/webform/complete`;

    const config = {
      body,
      headers: await this.#getHeaders(HttpMethods.Post, JSON.stringify(body)),
    };

    try {
      return await axios.post(url, body, config);
    } catch (err: any) {
      console.error(err);
      const { status, headers } = err.response;
      return { data: null, status, headers };
    }
  }

  async getWebform() {
    const url = `${this.#url()}/wf/webform/auth`;

    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    try {
      return await axios.get(url, config);
    } catch (err: any) {
      console.error(err);
      const { status, headers } = err.response;
      return { data: null, status, headers };
    }
  }

  async getScript(id: string) {
    const url = `${this.#url()}/wf/script/${id}`;

    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    return await axios.get<ContactFlow>(url, config);
  }

  async getCustomer(campaignId: string, customerId: string) {
    const url = `${this.#url()}/wf/campaign/${campaignId}/customer/${customerId}`;

    const config = {
      headers: await this.#getHeaders(HttpMethods.Get),
    };

    return await axios.get<ICustomer>(url, config);
  }
}
