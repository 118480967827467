import { useMemo } from "react";
import QuestionAnswer from "./QuestionAnswer";
import { AskModule, Attributes, Module } from "./types";
import { replaceAttributes } from "./utils";

export interface QuestionStepProps {
  attributes: Attributes;
  module: AskModule;
  onChangeAnswer: (module: Module, value: string) => void;
}

export const QuestionStep = ({
  module,
  attributes,
  onChangeAnswer: handleChangeAnswer,
}: QuestionStepProps) => {
  const label = useMemo(() => {
    return replaceAttributes(module.text, attributes);
  }, [attributes, module.text]);

  return (
    <>
      {/* Accessibility - Page should contain a level-one heading */}
      <h1>{label}</h1>
      <QuestionAnswer
        attributes={attributes}
        label={label}
        module={module}
        onChange={(module: Module, value: string) => {
          handleChangeAnswer(module, value);
        }}
      />
    </>
  );
};
