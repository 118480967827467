import { isValid, parseISO } from "date-fns";
import {
  AttributeCondition,
  AttributeConditions,
  Attributes,
  DecisionModule,
  Module,
  ModuleTypes,
} from "./types";

export const TextAreaMaxLength = 500;

export const replaceAttributes = (
  text: string,
  eventAttributes: Attributes
): string => {
  const attributes = [];
  let match;
  const re = /{([^}]+)}/g;

  while ((match = re.exec(text))) {
    attributes.push(match[1]);
  }

  attributes.forEach((attribute) => {
    text = text.replaceAll(`{${attribute}}`, eventAttributes[attribute]);
  });

  return text;
};

const evaluateAttributeDecision = (
  attribute: string,
  attributes: Attributes,
  condition: AttributeCondition,
  value: string
): boolean => {
  const dateIfValid = (potentialDate: string) => {
    const date = parseISO(potentialDate);
    return isValid(date) ? date.valueOf() : potentialDate;
  };

  const attributeValue = dateIfValid(attributes[attribute]);
  const parsedValue = dateIfValid(replaceAttributes(value, attributes));

  switch (condition) {
    case AttributeConditions.DoesNotEqual:
      return attributeValue !== parsedValue;
    case AttributeConditions.Equals:
      return attributeValue === parsedValue;
    case AttributeConditions.IsLessThan:
      return attributeValue < parsedValue;
    case AttributeConditions.IsLessOrEqualTo:
      return attributeValue <= parsedValue;
    case AttributeConditions.IsGreaterThan:
      return attributeValue > parsedValue;
    case AttributeConditions.IsGreaterOrEqualTo:
      return attributeValue >= parsedValue;
    case AttributeConditions.StartsWith:
      return typeof attributeValue === "string"
        ? attributeValue.startsWith(parsedValue.toString())
        : true;
    case AttributeConditions.EndsWith:
      return typeof attributeValue === "string"
        ? attributeValue.endsWith(parsedValue.toString())
        : true;
    case AttributeConditions.Contains:
      return typeof attributeValue === "string"
        ? attributeValue.includes(parsedValue.toString())
        : true;
    default:
      return true;
  }
};

export const evaluateDecisionModule = (
  attributes: Attributes,
  module: DecisionModule
): boolean => {
  switch (module.check) {
    case "Attribute":
      return evaluateAttributeDecision(
        module.attribute,
        attributes,
        module.condition,
        module.value
      );
    default:
      return false;
  }
};

export const flattenScriptModules = (
  attributes: Attributes,
  modules: Module[]
): Module[] => {
  // Flattening hierarchical data
  return [...modules].reduce<Module[]>((accumulator, current) => {
    const previous = accumulator[accumulator.length - 1];

    if (
      (previous?.type === ModuleTypes.Ask &&
        !questionAnswered(previous?.attribute, attributes)) ||
      previous?.type === ModuleTypes.EndCall
    ) {
      // Don't add any more modules.
      return accumulator;
    }

    if (current.type === ModuleTypes.Attribute) {
      attributes[current.attribute] = replaceAttributes(
        current.value,
        attributes
      );

      return accumulator;
    }

    if (
      current.type === "Decision" &&
      evaluateDecisionModule(attributes, current)
    ) {
      return [
        ...accumulator,
        ...flattenScriptModules(attributes, current.modules),
      ];
    }

    if (
      current.type === "Decision" &&
      !evaluateDecisionModule(attributes, current)
    ) {
      return accumulator;
    }

    return [...accumulator, current];
  }, []);
};

export const processScript = ({
  attributes,
  modules,
}: {
  attributes: Attributes;
  modules?: Module[];
}) => {
  return {
    flattenedModules: modules ? flattenScriptModules(attributes, modules) : [],
    attributes,
  };
};

const questionAnswered = (attribute: string, attributes: Attributes): boolean =>
  Object.keys(attributes).includes(attribute);

export const deleteAttributesRecursive = (
  attributes: Attributes,
  modules: Module[]
): Attributes => {
  const updateAttributes = { ...attributes };

  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    switch (module.type) {
      case ModuleTypes.Ask:
      case ModuleTypes.Attribute: {
        delete updateAttributes[module.attribute];
        break;
      }
      case ModuleTypes.Decision:
        deleteAttributesRecursive(updateAttributes, module.modules);
        break;
      default:
        break;
    }
  }

  return updateAttributes;
};

export const deleteAttributeModulesRecursive = (
  attributeModules: any,
  modules: Module[]
): any => {
  const updateAttributeModules = [...attributeModules];

  for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    switch (module.type) {
      case ModuleTypes.Ask:
      case ModuleTypes.Attribute: {
        removeFromAttributeModules(updateAttributeModules, module.attribute);
        break;
      }
      case ModuleTypes.Decision:
        deleteAttributeModulesRecursive(updateAttributeModules, module.modules);
        break;
      default:
        break;
    }
  }

  return updateAttributeModules;
};

export const findAttributeModule = (
  attributeModules: any,
  attribute: string
): any => {
  return attributeModules.find((i: any) => i.attribute === attribute);
};

const removeFromAttributeModules = (
  attributeModules: any,
  attribute: string
): any => {
  const index = attributeModules.findIndex(
    (i: any) => i.attribute === attribute
  );
  return [
    ...attributeModules.slice(0, index),
    ...attributeModules.slice(index),
  ];
};
