import { Attributes } from "./Types";

export enum HttpMethods {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "DELETE",
}

export type HttpMethod = `${HttpMethods}`;

export type CompleteWebformRequest = {
  actionId: string;
  attributes?: Attributes;
  campaignId: string;
  completedWhen: string;
  customerId: string;
  outcome?: string;
  scriptId?: string;
};

export enum AuthDenyReasons {
  // params
  MissingParams = "MISSING_PARAMETERS",
  AuthMismatch = "AUTH_MISMATCH",

  // internal
  WebformConfig = "WEBFORM_MISCONFIGURATION",
  WebformCompleted = "WEBFORM_ALREADY_COMPLETE",
  WebformCampaign = "WEBFORM_CAMPAIGN_UNAVAILABLE",

  // frontend only
  Unknown = "UNKNOWN",
}
