import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { ReactNode } from "react";

const theme = createTheme({
  breakpoints: {
    // https://service-manual.nhs.uk/design-system/styles/layout#screen-size
    values: {
      xs: 0,
      sm: 320,
      md: 641,
      lg: 769,
      xl: 990,
    },
  },
});

export default function ThemeProvider({ children }: { children: ReactNode }) {
  return (
    <MuiThemeProvider theme={responsiveFontSizes(theme)}>
      {children}
    </MuiThemeProvider>
  );
}
