import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingIcon = (): JSX.Element => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", paddingBottom: "2em" }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingIcon;
