import { useMediaQuery, useTheme } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { Input, InputProps } from "../";
import { useContext } from "react";
import { AuthContext } from "../../contexts";

interface InputFormikProps extends InputProps {
  name: string;
}

interface FormValues {
  [key: string]: string;
}

export default function InputFormik({
  width,
  name,
  ...props
}: InputFormikProps) {
  const [field, { error, touched }, { setValue }] = useField<string>(name);
  const theme = useTheme();
  const mobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const { setFieldError } = useFormikContext<FormValues>();
  const { clearSignInError } = useContext(AuthContext);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (touched) {
      setFieldError(name, "");
      clearSignInError();
    }
    setValue(e.target.value);
  };

  return (
    <Input
      {...props}
      {...field}
      error={error}
      width={mobileOrTablet ? undefined : width}
      onChange={handleFieldChange}
    />
  );
}
