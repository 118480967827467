export type Attributes = {
  [name: string]: string;
};

export type ContactFlow = {
  hours: Hours;
  website: {
    during: Module[];
  };
};

export const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

type HoursOfOperation = {
  from: string;
  to: string;
};

export type Hours = {
  sunday: HoursOfOperation;
  monday: HoursOfOperation;
  tuesday: HoursOfOperation;
  wednesday: HoursOfOperation;
  thursday: HoursOfOperation;
  friday: HoursOfOperation;
  saturday: HoursOfOperation;
};

export type ModuleType = `${ModuleTypes}`;

export enum ModuleTypes {
  Ask = "Ask",
  Attribute = "Attribute",
  Decision = "Decision",
  EndCall = "EndCall",
  Say = "Say",
}

export type Module =
  | SayModule
  | AskModule
  | AttributeModule
  | DecisionModule
  | EndCallModule;

export type SayModule = {
  id: string;
  type: ModuleTypes.Say;
  text: string;
};

export type AskModule = {
  id: string;
  type: ModuleTypes.Ask;
  attribute: string;
  intent: Intent;
  text: string;
  value?: string;
  validation?: {
    minLength?: number;
    maxLength?: number;
  };
};

export type EndCallModule = {
  id: string;
  text?: string;
  type: ModuleTypes.EndCall;
};

export type AttributeModule = {
  id: string;
  type: ModuleTypes.Attribute;
  text?: string;
  attribute: string;
  value: string;
};

type AttributeDecisionModule = {
  id: string;
  type: ModuleTypes.Decision;
  attribute: string;
  check: "Attribute";
  condition: AttributeCondition;
  modules: Module[];
  value: string;
};

type HoursOfOperationDecisionModule = {
  id: string;
  type: ModuleTypes.Decision;
  attribute: string;
  check: "HoursOfOperation";
  condition: HoursOfOperationCondition;
  modules: Module[];
  value: string;
};

export type DecisionModule =
  | AttributeDecisionModule
  | HoursOfOperationDecisionModule;

export type AttributeCondition = `${AttributeConditions}`;

export enum AttributeConditions {
  DoesNotEqual = "DoesNotEqual",
  Equals = "Equals",
  IsLessThan = "IsLessThan",
  IsLessOrEqualTo = "IsLessOrEqualTo",
  IsGreaterThan = "IsGreaterThan",
  IsGreaterOrEqualTo = "IsGreaterOrEqualTo",
  StartsWith = "StartsWith",
  EndsWith = "EndsWith",
  Contains = "Contains",
}

export type HoursOfOperationCondition = `${HoursOfOperationConditions}`;

export enum HoursOfOperationConditions {
  InHours = "InHours",
  OutOfHours = "OutOfHours",
}

type Intent = `${Intents}`;

export enum Intents {
  Date = "Date",
  Number = "Number",
  OpenEnded = "OpenEnded",
  YesNo = "YesNo",
}
