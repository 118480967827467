import { Breadcrumb, Col, Label, Row } from "nhsuk-react-components";

function YourPrivacy() {
  return (
    <>
      <Row>
        <Col width="two-thirds">
          <h2>
            Your privacy on the NHS MLCSU ‘Waiting List Validation’ website to
            support systems Elective Care Recovery
          </h2>
          <h3>Our privacy policy</h3>
          <p>
            NHS Midlands and Lancashire Commissioning Support Unit (We, Our, Us
            and all similar references) are committed to protecting and
            respecting your privacy.
          </p>
          <p>
            This policy sets out the basis on which any personal data we collect
            regarding you from the Hospital Trust, or that which the Hospital
            Trust provide to Us, will be processed by us. Please read the
            following carefully to understand how we look after your personal
            data when the Hospital Trust transfers this to our restricted
            SharePoint site and tell you about your privacy rights and how the
            law protects you.
          </p>
          <p>
            We are NHS Midlands and Lancashire Commissioning Support Unit. Our
            registered address is Heron House, Midlands and Lancashire CSU HQ,
            120 Grove Road, Stoke on Trent, ST4 4LX.
          </p>
          <p>
            You can contact us by post at the above address (Attention to
            Referral Management Centre) or by email at{" "}
            <a href="mailto:mlcsu.practicesupport@nhs.net">
              mlcsu.practicesupport@nhs.net
            </a>
          </p>
          <p>
            Any enquiries about our use of your personal data should be
            addressed to our Data Protection Officer using the above address or
            email <a href="mailto:mlcsu.ig@nhs.net">mlcsu.ig@nhs.net</a>
          </p>
          <h3>What data do we collect?</h3>
          <p>
            When the Hospital Trust sends the waiting list validation details to
            us, we receive the following information:
          </p>
          <ul>
            <li>NHS Number</li>
            <li>Local Patient Identifier</li>
            <li>First Name</li>
            <li>Surname</li>
            <li>DOB</li>
            <li>Phone Number</li>
            <li>Speciality</li>
            <li>Speciality Description</li>
            <li>Vulnerability Status</li>
            <li>Vulnerability Reason</li>
            <li>Patient Group (Inpatient/Outpatient)</li>
          </ul>
          <p>
            When you access the waiting list validation survey, you are the data
            subject who supplies the information necessary to update your status
            on the waiting list. You will be required to input:
          </p>
          <ul>
            <li>Telephone Number (prefilled)</li>
            <li>DOB</li>
            <li>Passcode (provided on the text message)</li>
          </ul>
          <h3>How we use your Personal Data</h3>
          <p>
            We will use your personal data only when the law allows us to. Most
            commonly, we will use your personal data in the following ways:
          </p>
          <ul>
            <li>
              To enable you, as a user, to access the waiting list validation
              survey questions to enable the Hospital Trust to understand your
              current status.
            </li>
            <li>
              To enable the Hospital Trust to gain insight into your current
              status while on the waiting list.
            </li>
          </ul>
          <h3>What do we do with your information?</h3>
          <p>
            Your information is securely stored in our database. The data
            collected by the system is only available to MLCSU and the Hospital
            Trust who has provided the list of patients (due for validation on
            their waiting list) and our Third-Party supplier Digital Space who
            manage the software of the cloud phone and text messaging system. We
            will not share your data with any non-related third parties. Your
            data will only be stored in the UK and nothing will be sent or
            stored outside of the UK.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we will do our best to protect your
            personal data, we cannot guarantee the security of your data
            transmitted to the ‘Waiting List Validation’ website.
          </p>
          <p>
            MLCSU will comply with any legal obligation to share personal data,
            or to protect the rights, property, or safety of MLCSU, our
            registered users and customers. This includes exchanging information
            with other companies and organisations for the purposes of fraud
            protection and credit risk reduction and to aid the prevention of
            cybercrime.
          </p>
          <p>
            We may use cookies or browser storage to temporarily store
            information on your device. This information will be used to secure
            your login session, remember the application state, and to cache
            data when the device is without an Internet connection.
          </p>
          <h3>Data Retention</h3>
          <p>
            We will only retain your personal data for as long as is reasonably
            necessary to fulfil the purposes for which we collected it,
            including satisfying any legal, regulatory, accounting or reporting
            requirements.
          </p>
          <h3>Your rights</h3>
          <p>
            <strong>Request access</strong> to your personal data (commonly
            known as a &quot;data subject access request&quot;). This enables
            you to receive a copy of the personal data we hold about you and to
            check that we are lawfully processing it.
          </p>
          <p>
            <strong>Request correction</strong> of the personal data that we
            hold about you. This enables you to have any incomplete or
            inaccurate data we hold about you corrected, though we may need to
            verify the accuracy of the new data you provide to us.
          </p>
          <p>
            <strong>Request erasure</strong> of your personal data. This enables
            you to ask us to delete or remove personal data where there is no
            good reason for us continuing to process it. You also have the right
            to ask us to delete or remove your personal data where you have
            successfully exercised your right to object to processing (see
            below), where we may have processed your information unlawfully or
            where we are required to erase your personal data to comply with
            local law. Note, however, that we may not always be able to comply
            with your request of erasure for specific legal reasons which will
            be notified to you, if applicable, at the time of your request.
          </p>
          <p>
            <strong>Object to processing</strong> of your personal data where we
            are relying on a legitimate interest (or those of a third party) and
            there is something about your situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms. You also have the right to object
            where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your
            rights and freedoms.
          </p>
          <p>
            <strong>Request restriction</strong> of processing of your personal
            data. This enables you to ask us to suspend the processing of your
            personal data in the following scenarios:
          </p>
          <ul>
            <li>If you want us to establish the data&apos;s accuracy</li>
            <li>
              Where our use of the data is unlawful, but you do not want us to
              erase it
            </li>
            <li>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims
            </li>
            <li>
              You have objected to our use of your data, but we need to verify
              whether we have overriding legitimate grounds to use it.
            </li>
          </ul>
          <p>
            <strong>Request the transfer</strong> of your personal data to you
            or to a third party. We will provide to you, or a third party you
            have chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </p>
          <p>
            <strong>Withdraw consent</strong> at any time where we are relying
            on consent to process your personal data. However, this will not
            affect the lawfulness of any processing carried out before you
            withdraw your consent. If you withdraw your consent, we may not be
            able to provide certain products or services to you. We will advise
            you if this is the case at the time you withdraw your consent.
          </p>
          <h3>Changes to Privacy Policy</h3>
          <p>
            We aim to keep our privacy policy under regular review. This version
            was last updated in May 2023.
          </p>
          <p>
            Any changes We may make to our privacy policy in the future will be
            posted on this page. The new terms may be displayed on-screen and
            you will be required to read and accept them to continue your use of
            the WLV website.
          </p>
          <h3>Your right to complain</h3>
          <p>
            If you have a complaint about our use of your information, you can
            contact the Information Commissioner&apos;s Office via their website
            at{" "}
            <a href="https://www.ico.org.uk/concerns">
              www.ico.org.uk/concerns
            </a>{" "}
            or write to them at:
          </p>
          <p>
            Information Commissioner’s Office
            <br />
            Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF
          </p>
          <p>
            I have read the Privacy Notice above, and I consent MLCSU holding my
            personal data for NHS Waiting List Validation Programme of Work for
            data collection and updating your Hospital Trust with the outcomes.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default YourPrivacy;
