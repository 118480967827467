import { Breadcrumb, Col, Label, Row } from "nhsuk-react-components";

function AccessibilityStatement() {
  return (
    <>
      <Row>
        <Col width="two-thirds">
          <Label isPageHeading>Accessibility statement</Label>
          <p>
            We aim to make the NHS patient waiting list as accessible and easy
            to use as possible.
          </p>
          <p>
            We&apos;ve designed our pages to meet the Web Content Accessibility
            Guidelines to level AA - and in some cases AAA - and we&apos;ve
            tested them with users with access needs.
          </p>
        </Col>
        <Col width="two-thirds">
          <h2>How to contact us</h2>
          <p>
            If you have any questions about accessibility, please email us at{" "}
            <a href="mailto:mlcsu.practicesupport@nhs.net">
              mlcsu.practicesupport@nhs.net
            </a>
            . We will respond within 5 working days.
          </p>
          <p>Updated: 1st June 2023</p>
        </Col>
      </Row>
    </>
  );
}

export default AccessibilityStatement;
