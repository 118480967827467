import { useMediaQuery, useTheme } from "@mui/material";
import { Input as NhsInput } from "nhsuk-react-components";
import { FormElementProps } from "nhsuk-react-components/dist/util/types/FormTypes";
import { InputWidth } from "nhsuk-react-components/dist/util/types/NHSUKTypes";
import { HTMLProps, MutableRefObject } from "react";

// Copied from node_modules\nhsuk-react-components\dist\components\input\Input.d.ts
export interface InputProps
  extends HTMLProps<HTMLInputElement>,
    FormElementProps {
  disableErrorLine?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  width?: InputWidth;
}

export default function Input({ width, ...props }: InputProps) {
  const theme = useTheme();
  const mobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return <NhsInput {...props} width={mobileOrTablet ? undefined : width} />;
}
