import React, { createContext, Dispatch, useState } from "react";
import { Customer } from "../services/Types";

const CustomerContext = createContext<{
  customer: Customer;
  setCustomer: Dispatch<Customer>;
}>({
  customer: {},
  setCustomer: () => {},
});

const CustomerProvider = ({ children }: { children: React.ReactNode }) => {
  const [customer, setCustomer] = useState<Customer>({});

  return (
    <CustomerContext.Provider value={{ customer, setCustomer }}>
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext, CustomerProvider as default };
