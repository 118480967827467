import { Breadcrumb, Col, Label, Row } from "nhsuk-react-components";
import { ColWidth } from "nhsuk-react-components/dist/util/types/NHSUKTypes";

type TermsAndConditionsProps = {
  width?: ColWidth;
};

function TermsAndConditions(props: TermsAndConditionsProps) {
  const { width = "two-thirds" } = props;
  return (
    <>
      <Row>
        <Col width={width}>
          <Label isPageHeading>Terms and Conditions</Label>
          <h2>1 Introduction</h2>
          <h3>1.1</h3>
          <p>
            The NHS Midlands and Lancashire Commissioning Support Unit (MLCSU)
            owns the NHS MLCSU &apos;Waiting List Validation&apos; survey
            website to support system Elective Care Recovery Teams and Hospital
            Trusts to validate their patient (Inpatient/Outpatient) waiting
            lists. This is achieved through a series of clinically designed
            questions to patients to assess if patients still required to be
            seen, require removal from the waiting list or require a clinical
            review whilst awaiting their appointment. The content, data and
            services on the &apos;Waiting List Validation&apos; survey website
            are delivered by MLCSU. You may access and use the &apos;Waiting
            List Validation&apos; survey website for this purpose if you agree
            to be legally bound by the terms set out here. If you do not agree
            to be legally bound by these terms, please do not access and/or use
            the &apos;Waiting List Validation&apos; survey website.
          </p>
          <h3>1.2</h3>
          <p>
            The &apos;Waiting List Validation&apos; survey website is intended
            for use only by patients on the co-hort provided by the hospital
            trust undertaking a validation exercise of patients on their waiting
            lists. Patients to be involved will receive a text message
            containing the survey.
          </p>
          <h3>1.3</h3>
          <p>
            If you use the &apos;Waiting List Validation&apos; survey website
            and undertake the survey on behalf of someone else:
          </p>
          <ol className="lower-alpha-nhsuk">
            <li>these terms apply to your use;</li>
            <li>
              you are responsible for such access and use, including being
              responsible for compliance with these terms; and
            </li>
            <li>
              you must, to the extent possible, make the person you are using
              the &apos;Waiting List Validation&apos; survey website on behalf
              of aware of: (i) your access and use and any steps you take on
              their behalf, and (ii) these terms.
            </li>
          </ol>
          <h2>2 Changes to terms</h2>
          <h3>2.1</h3>
          <p>
            We may make changes to the &apos;Waiting List Validation&apos;
            survey website, including these terms, at any time. You will be
            legally bound by the updated or amended terms from the first time
            that you use the &apos;Waiting List Validation&apos; survey website
            after we publish the changes on it.
          </p>
          <h2>3 Intellectual property rights</h2>
          <h3>3.1</h3>
          <p>
            The MLCSU own or have the right to use all intellectual property
            rights in the contents of the &apos;Waiting List Validation&apos;
            survey website or used for the provision of the &apos;Waiting List
            Validation&apos; survey website. This includes rights in copyright,
            patents, database rights, trademarks, and other intellectual
            property rights.
          </p>
          <h3>3.2</h3>
          <p>
            You have permission to use the &apos;Waiting List Validation&apos;
            survey website for the sole purposes described, and on the terms
            detailed, in these terms and conditions. You need written permission
            from us or any other owner of &apos;Waiting List Validation&apos;
            survey website content before you may use these items in any other
            way.
          </p>
          <h3>3.3</h3>
          <p>
            Copyright and database rights in the &apos;Waiting List
            Validation&apos; survey website content are released free-of-charge
            under the current version of the{" "}
            <a
              href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Government Licence
            </a>{" "}
            (&quot;OGL&quot;), except where specified, either in these terms and
            conditions, elsewhere on this website or in the OGL terms. If there
            is any conflict between the OGL terms and these terms and
            conditions, these terms and conditions shall take precedence.
          </p>
          <h3>3.4</h3>
          <p>
            This means that you can use &apos;Waiting List Validation&apos;
            survey website content, including copying it, adapting it, and using
            it for any purpose, including commercially, provided you follow
            these terms and conditions and the terms of the OGL.
          </p>
          <h3>3.5</h3>
          <p>
            The OGL terms do not apply to the following categories of NHS
            Website content, and therefore these must not be used without our or
            the relevant owner&apos;s prior consent:
          </p>
          <ul>
            <li>
              any logos, visuals, image rights, trademarks, trade names and
              design styles (except where these are integral to a document or
              data set) of the &apos;Waiting List Validation&apos; survey
              website, or any predecessor or linked body, as well as of any
              partners or contributors.
            </li>
            <li>
              any other intellectual property rights, including but not limited
              to patents, design rights and trademarks.
            </li>
            <li>
              personal data (which will include but not be limited to images,
              videos, and audio with identifiable people in them).
            </li>
            <li>
              information owned by third parties which we are not authorised to
              licence on to you. This includes everything itemised on our
              content not covered by our standard terms and conditions page,
              although we cannot guarantee that this list includes all
              information owned by third parties. Many images on the
              &apos;Waiting List Validation&apos; survey website are included as
              these are licensed by stock sites. If in doubt, contact us using
              the details below (14.3).
            </li>
          </ul>
          <h3>3.6</h3>
          <p>
            <strong>(a)</strong> If you access the &apos;Waiting List
            Validation&apos; survey website:
          </p>
          <ul>
            <li>
              at the frequency specified on our standard terms and conditions
              page in the case of the subset of &apos;Waiting List
              Validation&apos; survey website content specified; or
            </li>
            <li>
              at least every 7 days in respect of all other &apos;Waiting List
              Validation&apos; survey website content.
            </li>
          </ul>
          <p>
            then you must attribute us as follows: &quot;Information from the
            &apos;Waiting List Validation&apos; survey website&quot; unless you
            have our written consent to an alternative attribution. The relevant
            attribution must be made for every separate instance, use or
            appearance of &apos;Waiting List Validation&apos; survey website
            content, and in each case, you must make a clear distinction between
            &apos;Waiting List Validation&apos; survey website content and your
            other content.
          </p>
          <p>
            If you are displaying &apos;Waiting List Validation&apos; survey
            website content in a context where a functional link back to the
            article on the &apos;Waiting List Validation&apos; survey website is
            possible, then each attribution should link to the page on the
            &apos;Waiting List Validation&apos; survey website that the relevant
            &apos;Waiting List Validation&apos; survey website content has been
            supplied from.
          </p>
          <p>
            You must additionally publish, in a prominent and noticeable
            location within your site or service the statement that
            &quot;Information from the &apos;Waiting List Validation&apos;
            survey website is licensed under the Open Government Licence
            v3.0&quot; and, where possible, include a link to the{" "}
            <a
              href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Government Licence
            </a>
            .
          </p>
          <p>
            <strong>(b)</strong> If you access the &apos;Waiting List
            Validation&apos; survey website:
          </p>
          <ul>
            <li>
              at the frequency specified on our standard terms and conditions
              page in the case of the subset of &apos;Waiting List
              Validation&apos; survey website content specified; or
            </li>
            <li>
              at least every 7 days in respect of all other &apos;Waiting List
              Validation&apos; survey website content.
            </li>
          </ul>
          <p>
            you must include the standard attribution &quot;Contains public
            sector information licensed under the Open Government Licence
            v3.0.&quot; set out in the OGL and, where possible, include a link
            to the publication of the{" "}
            <a
              href="http://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Government Licence
            </a>
            , unless you have our written consent to an alternative attribution.
            You must not attribute the content to the &apos;Waiting List
            Validation&apos; survey website or cite MLCSU specifically as the
            source of the adapted or non-refreshed content.
          </p>
          <p>
            The following is a (non-exhaustive) list of examples of changes to
            content that constitute adaptations:
          </p>
          <ul>
            <li>
              Any change to wording that serves to change its meaning or impact.
            </li>
            <li>
              Taking wording out of a context that is important to its meaning.
            </li>
            <li>Translation into another language.</li>
          </ul>
          <p>
            Any adaptation of &apos;Waiting List Validation&apos; survey website
            content or use of non-refreshed &apos;Waiting List Validation&apos;
            survey website content may invalidate its formal clinical approval;
            therefore, if you use any such &apos;Waiting List Validation&apos;
            survey website content you will bear any risk associated with such
            adaptation or failure to refresh.
          </p>
          <h3>3.7</h3>
          <p>
            You must not use &apos;Waiting List Validation&apos; survey website
            content for or in association with i) any purpose; or ii) a site or
            service; which is illegal, derogatory, or otherwise objectionable or
            that brings MLCSU and or the NHS into disrepute. If you process any
            personal data of your customers or users in association with your
            use of any &apos;Waiting List Validation&apos; survey website
            Content you must do so in compliance with all applicable data
            protection and privacy legislation and all relevant regulatory
            guidance, including General Data Protection Regulation and Data
            Protection Act 2018.
          </p>
          <h3>3.8</h3>
          <p>
            You may not directly or indirectly suggest any endorsement or
            approval by MLCSU or the NHS of your site or service, or any non-NHS
            entity, product or content or any views expressed within your site
            or service.
          </p>
          <h3>3.9</h3>
          <p>
            We reserve the right to alter, adapt, edit, modify or restrict the
            availability of &apos;Waiting List Validation&apos; survey website
            content for use on third-party sites at any time.
          </p>
          <h2>4 Medical information</h2>
          <h3>4.1</h3>
          <p>
            The &apos;Waiting List Validation&apos; survey website may provide
            anonymised medical information for use as information or for
            educational purposes. We do not warrant that information we provide
            will meet your health or medical requirements. It is up to you to
            contact a health professional if you are concerned about your
            health.
          </p>
          <h3>4.2</h3>
          <p>
            The &apos;Waiting List Validation&apos; survey website does not give
            medical advice in relation to any individual case or patient, nor
            does the &apos;Waiting List Validation&apos; survey website provide
            medical or diagnostic services.
          </p>
          <h2>5 Liability</h2>
          <h3>5.1</h3>
          <p>
            We accept no liability to you should the &apos;Waiting List
            Validation&apos; survey website not be available at any time.
          </p>
          <h3>5.2</h3>
          <p>
            You acknowledge and agree that we will not be responsible for any
            injury, loss, damage, costs, or expenses (whether direct or
            indirect) arising out of or relating to the use or misuse of the
            &apos;Waiting List Validation&apos; survey website, except to the
            extent that such liability cannot be excluded by law (i.e., our
            liability for death or personal injury arising from our negligence,
            or our fraud or fraudulent misrepresentation or any other liability
            that cannot be excluded or limited under English law). This means
            that we have no liability to you (or anyone else on whose behalf you
            may use the &apos;Waiting List Validation&apos; survey website) for:
          </p>
          <ol className="lower-alpha-nhsuk">
            <li>
              any financial loss you may suffer or any inability to claim any
              payments you may experience.
            </li>
            <li>
              any business loss you may suffer, including loss of revenue, loss
              of profits or loss of anticipated savings (whether those losses
              are the direct or indirect result of our default).
            </li>
            <li>
              any loss which you suffer other than because of our negligence or
              breach of statutory duty.
            </li>
            <li>
              any loss suffered due to the default of any party other than us.
            </li>
          </ol>
          <h3>5.3</h3>
          <p>
            Although we follow our governance processes and make reasonable
            efforts to maintain service and accurate, up-to-date content the
            &apos;Waiting List Validation&apos; survey website is provided
            &quot;as is&quot; and, to the extent permitted by law, we do not
            warrant or represent (expressly or impliedly and including but not
            limited to the implied warranties of satisfactory quality and
            fitness for a particular purpose) that the &apos;Waiting List
            Validation&apos; survey website or any content is accurate, complete
            or up-to-date, will meet your particular requirements or needs, will
            be available uninterrupted or error free, that defects will be
            corrected, or its supporting systems are free of viruses or bugs.
          </p>
          <h3>5.4</h3>
          <p>
            We do not accept any liability to you if we fail, or are interrupted
            or delayed in the performance of any obligation because of:
          </p>
          <ol className="lower-alpha-nhsuk">
            <li>
              the non-availability or failure of any telecommunications or
              computer services, systems, equipment, or software operated or
              provided by you or any third party.
            </li>
            <li>any other event not reasonably within our control.</li>
          </ol>
          <h3>5.5</h3>
          <p>
            We do not give any commitments or accept any liability to you in
            respect of content provided by other users of the website or third
            parties.
          </p>
          <h3>5.6</h3>
          <p>
            You acknowledge and agree that we will not be responsible for any
            injury, loss, or damage (whether direct or indirect) arising out of,
            or relating to the misuse of, or inappropriate reliance on the
            contents or advice provided via an external link on the
            &apos;Waiting List Validation&apos; survey website, except to the
            extent that such liability cannot be limited or excluded by law.
          </p>
          <h2>6 Attacks, unauthorised access, and other violations</h2>
          <h3>6.1</h3>
          <p>
            You must not try to gain unauthorised access to the &apos;Waiting
            List Validation&apos; survey website, our services, the servers on
            which they are stored, or any server, computer or database connected
            to them. You must not attack the &apos;Waiting List Validation&apos;
            survey website or services hosted on it in any way, (this includes
            denial-of-service attacks). We will report any attacks or attempts
            to gain unauthorised access to the site or service to the relevant
            law enforcement authorities and shall share information about you
            with them.
          </p>
          <h3>6.2</h3>
          <p>
            You must not use the &apos;Waiting List Validation&apos; survey
            website:
          </p>
          <ol className="lower-alpha-nhsuk">
            <li>
              to transmit any false, misleading, fraudulent, defamatory,
              offensive, or illegal communications.
            </li>
            <li>
              in a way that could damage, disable, overburden, impair or
              compromise our systems or security or interfere with other users.
            </li>
            <li>
              in any unlawful manner, for any unlawful purpose, or in any manner
              inconsistent with these terms of use, or act fraudulently or
              maliciously, for example, by hacking into or inserting malicious
              code, such as viruses or harmful data.
            </li>
          </ol>
          <h2>7 Remedies</h2>
          <h3>7.1</h3>
          <p>
            To the extent that we are practically able to do so, we may
            terminate your access to all or any part of &apos;Waiting List
            Validation&apos; survey website and services at any time without
            notice if you breach any of the terms.
          </p>
          <h3>7.2</h3>
          <p>
            We reserve the right to investigate complaints or reported
            violations of our terms and to take any action we consider
            appropriate, including but not limited to reporting any suspected
            unlawful activity to law enforcement officials, regulators, or other
            third parties and disclosing any information necessary or
            appropriate to such persons or entities relating to user
            information, email addresses, usage history, IP addresses and
            traffic information.
          </p>
          <h2>8 General</h2>
          <h3>8.1</h3>
          <p>
            If any of these terms are determined to be illegal, invalid, or
            otherwise unenforceable then the remaining terms shall remain in
            full force and effect.
          </p>
          <h3>8.2</h3>
          <p>
            We may transfer our rights and obligations under these terms to
            another organisation. You may only transfer your rights or your
            obligations under these terms to another person if we agree in
            writing.
          </p>
          <h3>8.3</h3>
          <p>
            Your use of the &apos;Waiting List Validation&apos; survey website
            and these terms, together with any service specific additional
            terms, shall be governed by and interpreted in accordance with the
            laws of England, and you agree to the exclusive jurisdiction of the
            courts of England in respect of any disputes that may arise in
            relation to the &apos;Waiting List Validation&apos; survey website.
          </p>
          <h3>8.4</h3>
          <p>
            The &apos;Waiting List Validation&apos; survey website is delivered
            for NHS Trusts by:
          </p>
          <p>
            NHS Midlands and Lancashire Commissioning Support Unit,
            <br />
            Heron House,
            <br />
            Midlands and Lancashire CSU HQ,
            <br />
            120 Grove Road,
            <br />
            Stoke on Trent,
            <br />
            ST4 4LX
          </p>
          <p>Page last reviewed: 1st June 2023</p>
        </Col>
      </Row>
    </>
  );
}

export default TermsAndConditions;
