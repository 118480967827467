import { CompleteWebformRequest } from "../services/Types.crm";
import WebformService from "../services/WebformService";
import { ICustomer, IScript } from "../services/Types";
import { ContactFlow } from "../Script/types";

type ResponseWrapper<T> = [data: T | null, denyReason?: string];

export default class Webform {
  #service: WebformService;

  constructor(service: WebformService) {
    this.#service = service;
  }

  async completeWebform(request: CompleteWebformRequest) {
    const response = await this.#service.completeWebform(request);

    if (response?.status === 200) {
      return response.data;
    } else {
      throw new Error("Complete webform failed");
    }
  }

  async getWebform(): Promise<ResponseWrapper<any>> {
    const response = await this.#service.getWebform();

    if (response?.status === 200) {
      return [response.data];
    } else {
      return [null, response.headers["x-auth-deny-reason"]];
    }
  }

  async getScript(id: string): Promise<ResponseWrapper<ContactFlow>> {
    const response = await this.#service.getScript(id);

    if (response?.status === 200) {
      return [response.data];
    } else {
      console.error("Failed getScript:", response);
      return [null, response.headers["x-auth-deny-reason"]];
    }
  }

  async getCustomer(
    campaignId: string,
    customerId: string
  ): Promise<ResponseWrapper<ICustomer>> {
    const response = await this.#service.getCustomer(campaignId, customerId);

    if (response?.status === 200) {
      return [response.data];
    } else {
      console.error("Failed getCustomer:", response);
      return [null, response.headers["x-auth-deny-reason"]];
    }
  }
}
