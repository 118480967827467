import { Button, ErrorMessage, InsetText } from "nhsuk-react-components";
import { useCallback, useState } from "react";
import { useCustomer, useWebform } from "../hooks";
import { CompleteWebformRequest } from "../services/Types.crm";
import { Attributes, Module } from "./types";
import { flattenScriptModules } from "./utils";

type ScriptProps = {
  attributes: Attributes;
  modules: Module[];
  onSuccess: () => void;
  onError: () => void;
};

const CompleteButton = ({
  attributes,
  modules,
  onSuccess,
  onError,
}: ScriptProps) => {
  const { customer } = useCustomer();
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { webform } = useWebform();

  const completeWebform = useCallback(
    async (request: CompleteWebformRequest) => {
      setSubmitting(true);
      try {
        await webform.completeWebform(request);
        onSuccess();
        setError(false);
      } catch (e) {
        setError(true);
        onError();
      }

      setSubmitting(false);
      setSubmitted(true);
    },
    [webform]
  );

  return (
    <>
      {submitted &&
        (error ? (
          <ErrorMessage>
            Unable to submit answers. Please try again later.
          </ErrorMessage>
        ) : (
          <InsetText>
            <p>Your answers have been submitted. You can now close this tab.</p>
          </InsetText>
        ))}
      <Button
        disabled={submitting || (submitted && !error)}
        onClick={async () => {
          await completeWebform({
            attributes,
            actionId: customer?.webform?.actionId,
            campaignId: customer?.webform?.campaignId,
            customerId: customer?.webform?.customerId,
            scriptId: customer?.webform?.scriptId,
            completedWhen: new Date().toISOString(),
          });
        }}
      >
        Submit
      </Button>
    </>
  );
};

export default CompleteButton;
