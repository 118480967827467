import { Footer as NhsFooter } from "nhsuk-react-components";

export default function Footer() {
  return (
    <NhsFooter>
      <NhsFooter.List columns>
        <NhsFooter.ListItem href={`/accessibility-statement`} target="_blank">
          Accessibility Statement
        </NhsFooter.ListItem>
        <NhsFooter.ListItem href={`/cookie-policy`} target="_blank">
          Cookie Policy
        </NhsFooter.ListItem>
        <NhsFooter.ListItem href={`/get-in-touch`} target="_blank">
          Get In Touch
        </NhsFooter.ListItem>
        <NhsFooter.ListItem href={`/terms-and-conditions`} target="_blank">
          Terms and Conditions
        </NhsFooter.ListItem>
        <NhsFooter.ListItem href={`/your-privacy`} target="_blank">
          Your Privacy
        </NhsFooter.ListItem>
      </NhsFooter.List>
      <NhsFooter.Copyright>&copy; Crown copyright</NhsFooter.Copyright>
    </NhsFooter>
  );
}
