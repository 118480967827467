import { Attributes, Module, ModuleTypes } from "./types";
import { replaceAttributes } from "./utils";
import { SummaryList } from "nhsuk-react-components";
import React from "react";

type SummaryProps = {
  modules: Module[]
  attributes: Attributes
}

const Summary = ({ modules, attributes }: SummaryProps) => {
  const summary = modules.reduce<Array<{[key: string]: string|number}>>((acc: any, i: any) => {
    if (i.type === ModuleTypes.Ask) {
      const question: string = replaceAttributes(i.text, attributes);
      const answer: string = attributes?.[i.attribute] || ''
      acc.push({ question, answer })
    }
    return acc;
  }, [])

  return (
    <div>
      <SummaryList>
        {summary.map((i) => <SummaryList.Row key={i.question}>
          <SummaryList.Key>{i.question}</SummaryList.Key>
          <SummaryList.Value>{i.answer}</SummaryList.Value>
        </SummaryList.Row>)
        }
      </SummaryList>
    </div>
  )
}

export default Summary