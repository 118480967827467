import React, { createContext } from "react";
import Webform from "../lib/Webform";
import WebformService from "../services/WebformService";

const webformService = new WebformService();
const webform = new Webform(webformService);

const WebformContext = createContext<{
  webformService: WebformService;
  webform: Webform;
}>({
  webformService,
  webform,
});

const WebformProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <WebformContext.Provider value={{ webformService, webform }}>
      {children}
    </WebformContext.Provider>
  );
};

export { WebformContext, WebformProvider as default };
