import { Header as NhsHeader } from "nhsuk-react-components";

export default function Header() {
  return (
    <NhsHeader style={{ marginBottom: "16px" }}>
      <NhsHeader.Container>
        <NhsHeader.Logo href="/" />
      </NhsHeader.Container>
    </NhsHeader>
  );
}
