import { Fragment } from "react";
import { QuestionStep } from "./QuestionStep";
import { SayStep } from "./SayStep";
import { Attributes, Module, ModuleTypes } from "./types";

type ScriptProps = {
  attributes: Attributes;
  onChangeAnswer: (module: Module, value: string) => void;
  module: Module;
};

const Step = ({
  attributes,
  onChangeAnswer: handleChangeAnswer,
  module,
}: ScriptProps) => {
  switch (module.type) {
    case ModuleTypes.Ask:
      return (
        <QuestionStep
          attributes={attributes}
          key={module.id}
          module={module}
          onChangeAnswer={handleChangeAnswer}
        />
      );
    case ModuleTypes.Say:
      return (
        <SayStep attributes={attributes} module={module} key={module.id} />
      );
    default:
      return <Fragment key={module.id} />;
  }
};

export default Step;
