import ReactDOM from "react-dom/client";
import App from "./App";
import {
  AuthProvider,
  CustomerProvider,
  ThemeProvider,
  WebformProvider,
} from "./contexts";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Footer, Header } from "./components";
import { Col, Container, Row } from "nhsuk-react-components";
import AccessibilityStatement from "./pages/AccessibilityStatement";
import CookiePolicy from "./pages/CookiePolicy";
import GetInTouch from "./pages/GetInTouch";
import YourPrivacy from "./pages/YourPrivacy";
import TermsAndConditions from "./pages/TermsAndConditions";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/accessibility-statement",
    element: <AccessibilityStatement />,
  },
  {
    path: "/cookie-policy",
    element: <CookiePolicy />,
  },
  {
    path: "/get-in-touch",
    element: <GetInTouch />,
  },
  {
    path: "/your-privacy",
    element: <YourPrivacy />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
]);
root.render(
  <>
    <CustomerProvider>
      <ThemeProvider>
        <WebformProvider>
          <AuthProvider>
            <Header />
            <main>
              <Container>
                <RouterProvider router={router} />
              </Container>
            </main>
            <Footer />
          </AuthProvider>
        </WebformProvider>
      </ThemeProvider>
    </CustomerProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
