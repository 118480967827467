import { Formik } from "formik";
import { DateTime } from "luxon";
import {
  Button,
  Checkboxes,
  ErrorSummary,
  Fieldset,
} from "nhsuk-react-components";
import { ReactNode, useContext, useState } from "react";
import * as yup from "yup";
import { AuthContext } from "../contexts";
import { AuthDenyReasons } from "../services/Types.crm";
import { CheckboxesFormik, DateFormik, InputFormik } from "./formik";
import TermsOfServiceModal from "./modals/TermsAndConditions/TermsAndConditionsModal";
import LoadingIcon from "./LoadingIcon/LoadingIcon";

type AuthenticatorFields = {
  agreedToTermsOfService: string[];
  dob: {
    day: number;
    month: number;
    year: number;
  };
  phoneNumber: string;
  code: string;
};

const checkDateValid = (day: any, month: any, year: any) => {
  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }
  const valueDate = DateTime.fromObject({
    day,
    month,
    year,
  });
  if (!valueDate.isValid) {
    return false;
  }
  return valueDate;
};

const validationSchema: yup.SchemaOf<AuthenticatorFields> = yup.object({
  agreedToTermsOfService: yup
    .array(
      yup
        .string()
        .required("Required")
        .oneOf(["true"], "You must agree to the terms and conditions")
    )
    .length(1, "You must agree to the terms and conditions")
    .required("Required"),
  dob: yup
    .object({
      // Use mixed type to avoid error casting to a number. Validation is handled separately as the error is not linked to the object directly, but to a specific property. Property are not displayed by the NhsDateInput component.
      day: yup.mixed(),
      month: yup.mixed(),
      year: yup.mixed(),
    })
    .required()
    .test({
      name: "dob-valid",
      test: (value) => {
        const { day, month, year } = value;
        if (!day) {
          throw new yup.ValidationError("Day is required", value, "dob");
        }
        if (!month) {
          throw new yup.ValidationError("Month is required", value, "dob");
        }
        if (!year) {
          throw new yup.ValidationError("Year is required", value, "dob");
        }
        const validDate = checkDateValid(day, month, year);
        if (!validDate) {
          throw new yup.ValidationError(
            "This is not a recognised date, please check and try again",
            value,
            "dob"
          );
        }
        if (validDate.startOf("day") > DateTime.now().startOf("day")) {
          throw new yup.ValidationError(
            "Date cannot be in the future",
            value,
            "dob"
          );
        }
        return true;
      },
    }),
  phoneNumber: yup
    .string()
    .required("Required")
    .matches(
      /^(\+44|0)7[0-9]{9}$/,
      "The phone number you've entered is invalid"
    ),
  code: yup
    .string()
    .required("Required")
    .matches(/^[a-zA-Z0-9]{5}$/, "Invalid code"),
});

type queryParamsOutput = {
  phoneNumber?: string;
  code?: string;
};

const getQueryParams = (): queryParamsOutput => {
  try {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);

    const tmp = {
      phoneNumber: urlParams.get("cid") ?? null,
      code: urlParams.get("code") ?? "",
    };

    const params: queryParamsOutput = {};

    if (tmp.phoneNumber && tmp.phoneNumber.match(/^(\+?44|0)7[0-9]{9}$/)) {
      // handle missing + at start of E.164 format
      if (tmp.phoneNumber.match(/^[^0|+]\d+$/)) {
        tmp.phoneNumber = `+${tmp.phoneNumber}`;
      }

      params.phoneNumber = tmp.phoneNumber;
    }

    if (tmp.code && tmp.code.match(/^[0-9a-zA-Z]{5}$/)) {
      params.code = tmp.code;
    }

    return params;
  } catch (err) {
    return {
      phoneNumber: "",
      code: "",
    };
  }
};

type AuthenticatorProps = {
  children: ReactNode;
  pageHeading: string;
};

const renderAuthErrorMessage = (reason?: string): JSX.Element => {
  switch (reason) {
    // campaign closed
    case AuthDenyReasons.WebformCampaign:
      return (
        <p>
          We have finished collecting details about patients on the waiting list
          and will contact you separately.
        </p>
      );

    // webform already submitted
    case AuthDenyReasons.WebformCompleted:
      return (
        <p>
          Your responses have already been recorded and there is no need to
          supply them again.
        </p>
      );

    default:
      return (
        <p>
          The information you provided does not match our records, please verify
          your details and try again.
        </p>
      );
  }
};

export default function Authenticator({
  children,
  pageHeading,
}: AuthenticatorProps) {
  const { signedIn, signInError, signInErrorReason, signIn } =
    useContext(AuthContext);
  const queryParams = getQueryParams();
  const [openModal, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const initialValues: AuthenticatorFields = {
    agreedToTermsOfService: [],
    dob: {
      day: 0,
      month: 0,
      year: 0,
    },
    phoneNumber: queryParams.phoneNumber ?? "",
    code: queryParams.code ?? "",
  };

  if (signedIn) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        try {
          setIsLoading(true);
          const dob = DateTime.fromObject({
            day: values.dob.day as number,
            month: values.dob.month as number,
            year: values.dob.year as number,
          }).toFormat("yyyy-MM-dd");
          await signIn({
            phoneNumber: values.phoneNumber,
            dob: dob,
            code: values.code,
          });
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.error(err);
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, handleSubmit }) => {
        if (Object.keys(errors).length > 0) {
          console.warn(errors);
        }

        return (
          <>
            <Fieldset>
              <Fieldset.Legend isPageHeading style={{ fontSize: "32px" }}>
                {pageHeading}
              </Fieldset.Legend>
            </Fieldset>

            {signInError && (
              <ErrorSummary
                aria-labelledby="error-summary-title"
                role="alert"
                tabIndex={-1}
              >
                {/* <ErrorSummary.Title id="error-summary-title">
                  There is a problem
                </ErrorSummary.Title> */}
                <ErrorSummary.Body>
                  {renderAuthErrorMessage(signInErrorReason)}
                </ErrorSummary.Body>
              </ErrorSummary>
            )}

            <InputFormik label="Phone Number" name="phoneNumber" width={10} />
            <DateFormik label="Date of Birth" name="dob" width={20} />
            <InputFormik label="Code" name="code" width={10} />
            <Fieldset>
              <CheckboxesFormik name="agreedToTermsOfService">
                <Checkboxes.Box value="true">
                  I have read and agreed to the{" "}
                  <a onClick={handleOpenModal}>Terms and Conditions</a>
                </Checkboxes.Box>
              </CheckboxesFormik>
            </Fieldset>
            <Button
              // Inline (i.e. () => handleSubmit) doesn't work
              onClick={() => {
                handleSubmit();
              }}
            >
              Sign in
            </Button>
            <TermsOfServiceModal
              handleCloseModal={handleCloseModal}
              openModal={openModal}
            ></TermsOfServiceModal>
          </>
        );
      }}
    </Formik>
  );
}
