import { Alert, Collapse, IconButton, createTheme } from "@mui/material";
import { CloseIcon, WarningCallout } from "nhsuk-react-components";
import { useEffect, useState } from "react";
import styles from "./BrowserUnloadWarning.module.scss";
import { Box, ThemeProvider } from "@mui/system";

const theme = createTheme();

export default function BrowserUnloadWarning() {
  const [warningOpen, setWarningOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);

  // const isSafariMobile = (): boolean => {
  //   const userAgent: string = navigator.userAgent;
  //   const isIOS: boolean =
  //     /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
  //   // Additional feature checks specific to Safari Mobile
  //   const supportsSafariFeatures: boolean =
  //     "ontouchstart" in window ||
  //     ((window as any).DocumentTouch &&
  //       document instanceof (window as any).DocumentTouch);
  //   return isIOS && supportsSafariFeatures;
  // };

  return (
    <>
      {isMobile && (
        <>
          <Collapse in={warningOpen}>
            <ThemeProvider theme={theme}>
              <Alert
                className="nhsuk-warning-callout-material"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setWarningOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
                icon={false}
                severity="warning"
                sx={{
                  mb: 2,
                  bgcolor: styles.color_nhsuk_pale_yellow,
                  color: styles.nhsuk_text_color,
                  borderBlockColor: styles.color_nhsuk_yellow,
                  borderBlockStyle: "solid",
                  borderWidth: "1px",
                  fontSize: "1em",
                  padding: "2em",
                  paddingTop: "0em",
                  marginBottom: "2em",
                }}
              >
                <small style={{ marginTop: "0em" }}>
                  Please use the back link at the top of this page to move to a
                  previous question. Do not refresh this page or your responses
                  will be lost
                </small>
              </Alert>
            </ThemeProvider>
          </Collapse>
        </>
      )}
    </>
  );
}
