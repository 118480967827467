import { Attributes, SayModule } from "./types";
import { replaceAttributes } from "./utils";

export interface SayStepProps {
  attributes: Attributes;
  module: SayModule;
}

export const SayStep = ({ module, attributes }: SayStepProps) => {
  return (
    // Accessibility - Page should contain a level-one heading
    <h1>{replaceAttributes(module.text, attributes)}</h1>
  );
};
