import { useMediaQuery, useTheme } from "@mui/material";
import { DateInput as NhsDateInput } from "nhsuk-react-components";
import { FormElementProps } from "nhsuk-react-components/dist/util/types/FormTypes";
import { ChangeEvent, HTMLProps } from "react";

// Copied from node_modules\nhsuk-react-components\dist\components\date-input\DateInput.d.ts
export type DateInputValue = {
  day: string;
  month: string;
  year: string;
};

// Copied from node_modules\nhsuk-react-components\dist\components\date-input\DateInput.d.ts
export type DateInputChangeEvent = ChangeEvent<HTMLInputElement> & {
    target: HTMLInputElement & {
        value: DateInputValue;
    };
    currentTarget: HTMLInputElement & {
        value: DateInputValue;
    };
};

// Copied from node_modules\nhsuk-react-components\dist\components\date-input\DateInput.d.ts
export interface DateInputProps extends Omit<HTMLProps<HTMLDivElement>, 'value' | 'defaultValue' | 'ref'>, FormElementProps {
  autoSelectNext?: boolean;
  value?: Partial<DateInputValue>;
  defaultValue?: Partial<DateInputValue>;
  onChange?: (e: DateInputChangeEvent) => void;
  handleChange?: (e: DateInputChangeEvent) => void;
  ref?: string; // NOTE: NHS Components built against different version of React, making the ref typing weird
}

export default function Input({ width, ...props }: DateInputProps) {
  const theme = useTheme();
  const mobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return <NhsDateInput {...props} width={mobileOrTablet ? undefined : width} />;
}
