import {
  Breadcrumb,
  Col,
  Details,
  Label,
  Row,
  Table,
} from "nhsuk-react-components";

function CookiePolicy() {
  return (
    <>
      <Row>
        <Col width="two-thirds">
          <Label isPageHeading>Cookie policy</Label>
        </Col>
        <Col width="two-thirds">
          <p>
            We are committed to providing transparency and clarity regarding the
            use of cookies on our website. We want to inform you that our
            website does not use cookies.
          </p>
          <h2>What are cookies?</h2>
          <p>
            Cookies are files that are typically saved on your phone, tablet, or
            computer when you visit a website. These files store information
            about your browsing behaviour, such as the pages you visit.
          </p>
          <p>
            However, we would like to emphasize that our website does not
            utilise cookies. We do not collect any information about your
            browsing activity, and we do not store any data on your device.
          </p>
          <h2>Cookies and your privacy</h2>
          <p>
            Since our website does not use cookies, you can rest assured that
            your privacy is fully protected. We prioritize the security and
            confidentiality of your personal information.
          </p>
          <h2>Third-party tools and cookies</h2>
          <p>
            Although we do not use cookies, it&apos;s worth mentioning that some
            tools or services provided by other organizations may be embedded
            within our website. These third-party tools may have their own
            policies and practices regarding the use of cookies.
          </p>
          <p>
            We advise you to review the cookie policies of any third-party
            websites or services that you may encounter while browsing our
            website. We do not have control over the cookies they set or the
            data they collect.
          </p>
          <h2>Your consent</h2>
          <p>
            As we do not use cookies on our website, there is no need for you to
            provide or manage your consent regarding their usage.
          </p>
          <h2>Contact us</h2>
          <p>
            If you have any questions or concerns about our cookie policy or how
            we handle your personal information, please don&apos;t hesitate to
            contact us. You can reach out to us through the contact information
            provided on our website.
          </p>
          <p>Last updated: 1st June 2023</p>
          <p>
            Please note that this policy may be subject to updates or revisions
            from time to time. We recommend visiting this page periodically to
            stay informed about any changes.
          </p>
        </Col>
      </Row>
    </>
  );
}

export default CookiePolicy;
