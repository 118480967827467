import { Breadcrumb, Col, Label, Row } from "nhsuk-react-components";

function GetInTouch() {
  return (
    <>
      <Row>
        <Col width="two-thirds">
          <Label isPageHeading>Get in touch</Label>
          <p>
            If you have got a question, idea or suggestion, please contact us by
            emailing{" "}
            <a href="mailto:mlcsu.practicesupport@nhs.net">
              mlcsu.practicesupport@nhs.net
            </a>
          </p>
        </Col>
      </Row>
    </>
  );
}

export default GetInTouch;
