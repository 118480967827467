import { Fieldset, Radios, Textarea } from "nhsuk-react-components";
import { Input } from "../components";
import { AskModule, Attributes, Intents, Module } from "./types";
import { useState } from "react";
// import { TextAreaMaxLength } from "./utils";

interface QuestionAnswerProps {
  attributes: Attributes;
  label: string;
  module: AskModule;
  onChange: (module: Module, value: string) => void;
}

const QuestionAnswer = ({
  attributes,
  label,
  module,
  onChange: handleChange,
}: QuestionAnswerProps) => {
  const TextAreaMaxLengthError = `This field has a maximum length of ${module.validation?.maxLength} characters.`;
  const TextAreaMinLengthError = `This field has a minimum length of ${module.validation?.minLength} characters.`;
  const attribute = module.attribute;
  const value = attributes?.[attribute] ?? "";
  const [count, setCount] = useState(value.length);
  const [error, setError] = useState<string | undefined>(
    module.validation?.maxLength && value.length > module.validation?.maxLength
      ? TextAreaMaxLengthError
      : undefined
  );

  switch (module.intent) {
    case Intents.Date: {
      return (
        <Input
          // Accessibility: Form elements must have labels
          aria-label={label}
          onChange={(event) => {
            handleChange(module, event.currentTarget.value);
          }}
          type="date"
          value={value ?? ""}
          width={10}
        />
      );
    }
    case Intents.Number:
      return (
        <Input
          // Accessibility: Form elements must have labels
          aria-label={label}
          onChange={(event) => {
            handleChange(module, event.currentTarget.value);
          }}
          type="number"
          value={value}
          width={10}
        />
      );
    case Intents.OpenEnded:
      return (
        <>
          <Textarea
            name={module.attribute}
            error={error}
            // Accessibility: Form elements must have labels
            aria-label={label}
            onChange={(event) => {
              setCount(event.currentTarget.value.length);
              if (
                module.validation?.maxLength &&
                event.currentTarget.value.length > module.validation?.maxLength
              ) {
                setError(TextAreaMaxLengthError);
              } else if (
                module.validation?.minLength &&
                event.currentTarget.value.length <= module.validation?.minLength
              ) {
                setError(TextAreaMinLengthError);
              } else {
                setError(undefined);
              }
              handleChange(module, event.currentTarget.value);
            }}
            value={value}
            width={10}
            rows={5}
          />
          {module.validation?.maxLength && (
            <div className="question-answer-character-count">
              {count}/{module.validation?.maxLength}
            </div>
          )}
        </>
      );
    case Intents.YesNo:
      return (
        <Fieldset>
          <Radios
            onChange={(event: any) => {
              handleChange(module, event.target.value);
            }}
          >
            <Radios.Radio value="Yes" checked={value === "Yes"}>
              {/* // TODO: Accessibility - Elements must have sufficient color contrast. */}
              Yes
            </Radios.Radio>
            <Radios.Radio value="No" checked={value === "No"}>
              {/* // TODO: Accessibility - Elements must have sufficient color contrast. */}
              No
            </Radios.Radio>
          </Radios>
        </Fieldset>
      );
    default:
      return <></>;
  }
};

export default QuestionAnswer;
