import { Col, Container, Row } from "nhsuk-react-components";
import "./App.scss";
import { Authenticator, Header } from "./components";
import { useCustomer } from "./hooks";
import Script from "./Script/Script";

function App() {
  const { customer } = useCustomer();

  return (
    <>
      <Row>
        <Col width="full">
          {/* Accessibility: document should have one main landmark. */}
          <Authenticator pageHeading="Waiting List Validation Platform">
            {customer?.webform?.scriptId &&
              customer?.webform?.campaignId &&
              customer?.webform?.customerId && (
                <Script
                  scriptId={customer?.webform?.scriptId}
                  campaignId={customer?.webform?.campaignId}
                  customerId={customer?.webform?.customerId}
                />
              )}
          </Authenticator>
        </Col>
      </Row>
    </>
  );
}

export default App;
