import React, { createContext, useState } from "react";
import useWebform from "../hooks/useWebform";
import { useCustomer } from "../hooks";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { AuthDenyReasons } from "../services/Types.crm";

export const AuthContext = createContext<{
  signedIn: boolean;
  signInError: boolean;
  signInErrorReason?: string;
  signIn: (values: SignInValues) => Promise<void>;
  signOut: () => void;
  clearSignInError: () => void;
}>({
  signedIn: false,
  signInError: false,
  signInErrorReason: undefined,
  signIn: async () => {},
  signOut: () => {},
  clearSignInError: () => {},
});

type SignInValues = {
  dob: string;
  phoneNumber: string;
  code: string;
};

export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { webformService, webform } = useWebform();

  const [signInError, setSignInError] = useState(false);
  const [signInErrorReason, setSignInErrorReason] = useState("");
  const [signedIn, setSignedIn] = useState(false);
  const { customer, setCustomer } = useCustomer();

  const clearSignInError = () => {
    setSignInError(false);
    setSignInErrorReason("");
  };

  const handleSignIn = async (values: SignInValues) => {
    const parsedPhoneNumber = parsePhoneNumberFromString(
      values.phoneNumber,
      "GB"
    )
      ?.formatInternational()
      .replace(/\s+/g, "");

    if (!parsedPhoneNumber) {
      setSignedIn(false);
      setSignInError(true);
      return;
    }

    webformService.setCustomAuthHeaders({
      "x-auth-type": "nhspwl",
      "x-auth-cid": parsedPhoneNumber,
      "x-auth-code": values.code,
      "x-auth-dob": values.dob,
    });

    const [responseData, denyReason] = await webform.getWebform();

    if (responseData && !denyReason) {
      setSignedIn(true);
      setSignInError(false);
      setCustomer({
        ...customer,
        webform: responseData,
      });
    } else {
      setSignedIn(false);
      setSignInError(true);
      setSignInErrorReason(denyReason ?? AuthDenyReasons.Unknown);
    }
  };

  const handleSignOut = () => setSignedIn(false);

  return (
    <AuthContext.Provider
      value={{
        signedIn,
        signInError,
        signInErrorReason,
        signIn: handleSignIn,
        signOut: handleSignOut,
        clearSignInError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
